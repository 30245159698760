<template>
  <b-card
    header-tag="header"
  >
    <h5 slot="header" class="mb-0 pr-3 text-center mj-header-styling">
      {{headermain}}
      {{headeradd}}
      <img v-if="circlecolor !== 'white'" slot="header" src="@/assets/icons/circle-thin.svg" class="to-right"/>
      <img v-else slot="header" src="@/assets/icons/circle-thin-white.svg" class="to-right"/>
    </h5>
    <b-card-text>
      <p>{{title}}</p>
      <slot/>
    </b-card-text>
    <!-- <b-button pill href="#" variant="primary">Go somewhere</b-button> -->
  </b-card>
</template>

<script>
  export default {
    name: 'MoonjellyCard',
    props: {
      circlecolor: String,  //white or black(default)
      headermain: String,
      headeradd: String,
      title: String
    }
  }

</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

.mj-header-styling {
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
}

.to-right {
  position: absolute;
  right: 12px;
  top: 12px;
}

.card {
  border: solid 1px #3d464f;
  border-radius: 16px;
  box-shadow: 6px 6px 0px 0px #3d464f;
}
.card-header:first-child {
  border-radius: 15px 15px 0 0;
}
.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #3d464f;
}
.btn {
  border-radius: 25px;
}

.form-control {
  border-color: #3E464E;
}

</style>
